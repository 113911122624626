import React, { useEffect, useState } from "react";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import DatepickerIcons from "../../assets/images/datepickerIcons.png";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextFieldProps, TextField } from "@mui/material";

function MonthYearPickerComponent(props: any) {
  const { handleFieldChange, editable, title, id, value, errors, isMaxDate } =
    props;

  const [isIphone, setIsIphone] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (userAgent.includes("iPhone")) {
      setIsIphone(true);
    } else {
      setIsIphone(false);
    }
  }, []);




  const onChangeRaw = (id: any, value: any) => {
    if (!moment(value, "MM/YYYY", true).isValid()) {
      handleFieldChange(id, value || "");
    } else {
      handleFieldChange(id, moment(value).format("MM/YYYY"));
    }
  };



  return (
    <div className="question_container mx-auto relative w-full" style={{ maxWidth: "300px" }}>
      <div className="question_inner_container text-start">
        <label
          className="input-label block ml-1 question_title"
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            fontSize: "14px",
            fontWeight: 400,
            color: "#6B7280",
          }}
        ></label>
      </div>
      <div id={id} className="mt-1 relative customDatepicker customDatepickerMonthYear">
        {value === 'PRESENT' ? <TextField fullWidth value={value} disabled={!editable} /> : <LocalizationProvider dateAdapter={AdapterDateFns} >
          <DatePicker

            className={isIphone ? "iPhonePicker" : ""}
            disabled={!editable}
            maxDate={!isMaxDate && new Date()}
            views={['month', 'year']}
            value={moment(value, "MM/YYYY", true).isValid() ? moment(value, "MM/YYYY").toDate() : null}
            onChange={(newValue: any) => onChangeRaw(id, newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>}

      </div>

      {errors && errors[id] && (
        <p style={{ color: "red" }} className="absolute left-0">
          {errors[id]}
        </p>
      )}
    </div>
  );
}

export default MonthYearPickerComponent;
