import React, { useEffect, useState } from "react";

import { Box, Stack, Typography } from "@mui/material";
import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAppSelector } from "../../store/store";
import { setLoading } from "../../store/slices/notificationSlice";
import { getProgressData } from "../../store/thunk/documentThunk";

import {
  checkDependant,
  structureArray,
  structureArrayQuestion,
} from "../../helper/service";
import RightIcon from "../../assets/images/RightSVG.svg";

const CustomProgressBar = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const roleData: any = localStorage.getItem("role");

  let localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = JSON.parse(localData);

  const { getSignleFormData, uiFormPayload } = useAppSelector(
    (state: any) => state?.formReducer
  );
  const { progressData } = useAppSelector(
    (state: any) => state?.documentReducer
  );
  const currentData = useAppSelector(
    (state: any) => state.formReducer.currentData
  );
  const [progressValues, setProgressValues] = useState([]);
  const [defaultSectionData, setDefaultSectionData] = useState<any>([]);
  const [fillData, setFillData] = useState<any>([]);
  const [verified, setVerified] = useState<any>(false);
  const [sections, setSections] = useState<any>([]);

  useEffect(() => {
    setVerified(bootstrapData?.isVerified || false);
  }, [bootstrapData]);

  useEffect(() => {
    if (getSignleFormData?.flowJson) {
      const nodeData = JSON.parse(getSignleFormData?.flowJson);
      const flag = bootstrapData?.features?.find((flag: any) => flag.name === "ShowEligibilityForClient");
      const hasClientShowEligibility = flag?.value;

      let roundedData: number = 0;
      if (nodeData) {
        let mainSectionData = null;

        if (roleData === "lawyer") {
          mainSectionData = nodeData?.nodes?.filter(
            (obj: any) =>
              obj?.data?.nodeType === "section" &&
              obj?.id !== "Special_Forms" &&
              obj?.id !== "Evidence_Engine" &&
              obj?.id !== "Pay_and_Download" &&
              obj?.id !== "Printsign"
          );
        } else if (roleData === "client" && hasClientShowEligibility) {
          mainSectionData = nodeData?.nodes?.filter(
            (obj: any) =>
              obj?.data?.nodeType === "section" &&
              obj?.id !== "Special_Forms" &&
              obj?.id !== "Evidence_Engine" &&
              obj?.id !== "Pay_and_Download" &&
              obj?.id !== "Printsign"
          );
        } else {
          mainSectionData = nodeData?.nodes?.filter(
            (obj: any) =>
              obj?.data?.nodeType === "section" &&
              obj?.id !== "General_Eligibility" &&
              obj?.id !== "Special_Forms" &&
              obj?.id !== "Evidence_Engine" &&
              obj?.id !== "Pay_and_Download" &&
              obj?.id !== "Printsign"
          );
        }

        setDefaultSectionData(mainSectionData);
        const progressSections = progressData?.map(
          (obj: any) => obj?.sectionKey
        );
        const totalQuestion = nodeData?.nodes?.filter(
          (obj: any) => obj?.data?.nodeType == "question"
        )?.length;
        const mainData = structureArrayQuestion(nodeData?.nodes);
        const findData = mainData?.filter((obj: any) =>
          progressSections.includes(obj?.id)
        );
        const findSectionQuestionTotal =
          findData &&
          findData?.map((obj: any) => {
            const childData = obj?.childItem?.length;
            const calculateData = (childData / totalQuestion) * 100;
            roundedData = Math.round(calculateData);
            return roundedData;
          });

        const totalSum = findSectionQuestionTotal?.reduce(
          (accumulator: any, currentValue: any) => accumulator + currentValue,
          0
        );
        const resultArr = findSectionQuestionTotal?.map((value: any) =>
          Math.round((value / totalSum) * 100)
        );

        setFillData(resultArr);
        const structureArrayData = structureArray(nodeData?.nodes);
        setSections(structureArrayData);
      }
    }
  }, [getSignleFormData, progressData, currentData]);

  // useEffect(() => {
  //   setLoading(true);
  //   dispatch(getProgressData());
  // }, []);

  useEffect(() => {
    const mergedData: any = defaultSectionData?.map((section: any) => {
      const progressSection = progressData?.filter(
        (obj: any) => obj?.sectionKey === section?.id
      );
      if (progressSection.length > 0) {
        return {
          ...progressSection[0],
          ...section,
        };
      } else {
        return section;
      }
    });

    const filterList = mergedData?.filter((val: any) =>
      checkDependant(
        val,
        Object.keys(currentData || {}).length ? currentData : uiFormPayload
      )
    );

    setProgressValues(filterList);
  }, [progressData, defaultSectionData, currentData]);

  const handleRedirection = (field: any) => () => {
    const selectedSection = sections?.find((obj: any) => obj?.id === field?.id);
    let selectedSectionSummary = null;

    if (selectedSection?.data?.nodeTitle === "Forms to be filed") {
      selectedSectionSummary =
        selectedSection?.childItem[selectedSection?.childItem?.length - 2];
    } else {
      selectedSectionSummary =
        selectedSection?.childItem[selectedSection?.childItem?.length - 1];
    }

    if (selectedSectionSummary) {
      navigate(`/eligibility-check/${selectedSection?.data?.nodeTitle}`, {
        state: { summaryId: selectedSectionSummary?.id },
      });
    }
  };

  const getProgressCircle = (value = 0, fieldId: any) => {
    return (
      <Box id={`${fieldId}_${value}`} className="progress_circle_container" sx={{ height: "40px" }}>
        <CircularProgress
          id={`${fieldId}_100_completed`}
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
          }}
          size={40}
          thickness={4}
          {...props}
          value={100}
        />
        <CircularProgress
          id={`${fieldId}_incomplete`}
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.mode === "light" ? "#2563EB" : "#308fe8",
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          value={value}
          size={40}
          thickness={4}
          {...props}
        />
      </Box>
    );
  };

  const renderSpaceWithUnderScore = (sectionId: string) => {
    let newStr = sectionId.replace(/[\s-]/g, "_");
    return newStr;
  }

  return (
    <>
      <Stack
        spacing={1}
        direction="row"
        justifyContent={"start"}
        gap={"40px"}
        className="progressBoxsContainer"
      >
        {verified &&
          progressValues &&
          progressValues?.length > 0 &&
          fillData?.length > 0 &&
          progressValues?.map((field: any, index: any) => (
            <Box
              key={index}
              className="progressBox"
              onClick={handleRedirection(field)}
              sx={{ cursor: "pointer" }}
            >
              <Box
                id={field?.id}
                className="progress_box_container"
                sx={{
                  position: "relative",
                  display: "table",
                  margin: "0 auto",
                }}
              >
                {field?.percentComplete && field?.percentComplete < 100 ? (
                  getProgressCircle(field?.percentComplete, field?.id)
                ) : field?.percentComplete && field?.percentComplete === 100 ? (
                  <img
                    src={RightIcon}
                    width="40px"
                    height="40px"
                    style={{ zIndex: "5", position: "relative" }}
                  />
                ) : (
                  getProgressCircle(field?.percentComplete, field?.id)
                )}

                <Box
                  id={`${field?.id}_percentage_container`}
                  className="progress_bar_percentage_container"
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    id={`${field?.id}_percentage`}
                    className="progress_bar_percentage_txt"
                    variant="caption"
                    component="div"
                    fontFamily="Sen"
                    color="#1C2333"
                  >{`${Math.round(
                    field?.percentComplete ? field?.percentComplete : 0
                  )}%`}</Typography>
                </Box>
              </Box>
              <Typography
                id={`${renderSpaceWithUnderScore(field?.data?.nodeTitle)}`}
                className="section-title progress_bar_section_name_txt"
                sx={{
                  marginTop: "5px",
                  fontSize: "14px",
                  lineHeight: "20px",
                  color: "#1C2333",
                }}
                variant="caption"
                component="div"
                textAlign="center"
                fontFamily="Sen"
              >
                {field?.data?.nodeTitle}
              </Typography>
            </Box>
          ))}
      </Stack>
    </>
  );
};

export default CustomProgressBar;
