import React from "react";

function MultiInputField(props: any) {
  const {
    title,
    placeholder,
    editable,
    handleFieldChange,
    id,
    value,
    errors,
    style,
  } = props;

  return (
    <div className="mt-8 mb-5">
      <div
        className="w-full mx-auto relative"
        style={{
          margin: style?.margin || "0px",
        }}
      >
        <div
          style={{
            margin: style?.labelMargin || "0px",
            paddingLeft: "1.3rem",
          }}
        >
          <label
            className="text-gray-500 text-sm font-normal block ml-1"
            dangerouslySetInnerHTML={{ __html: title }}
            style={{
              fontSize: style?.fontSize || "",
              fontWeight: style?.fontWeight || "",
              color: style?.color || "",
            }}
          >
            {/* {title} hide for dangerouslySetInnerHTML */}
          </label>
        </div>
        {/* <input
          className="custom-input px-6 py-1.5 peer h-full w-full mt-1 rounded-[48px] border border-blue-gray-200 border-t-[#D1D5DB] bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-[#D1D5DB] focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleFieldChange(id, e.target.value)}
        /> */}
        <textarea
          id={id}
          onBlur={() => handleFieldChange(id, value)}
          disabled={!editable}
          className="mt-1 p-[8px] w-full focus-visible:outline-0 disabled:bg-[rgb(236_239_241)]"
          rows={3}
          placeholder={placeholder}
          // style={{ width: "100%", padding: "8px" }}
          value={value}
          onChange={(e) => handleFieldChange(id, e.target.value)}
        ></textarea>
        {errors && errors[id] && (
          <p style={{ color: "red" }} className="absolute left-0">
            {errors[id]}
          </p>
        )}
      </div>
    </div>
  );
}

export default MultiInputField;
